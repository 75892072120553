<template>
    <!-- 子分类编辑 -->
    <div class="main">
        <div v-for="el in list" :key="el.id">
            <el-input v-model="el.title" size="small" placeholder="福利标题"></el-input>
            <el-input v-model="el.url" size="small" placeholder="福利点击链接"></el-input>
            <el-switch
                class="sw"
                v-model="el.status"
                active-color="#7781f1"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                active-text="显示"
                inactive-text="隐藏"
            >
            </el-switch>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: Array,
    },
    methods: {
        getData() {
            return [...this.list];
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    & > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & > div {
            flex: 1;
            margin: 0 10px;
        }
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
