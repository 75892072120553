<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>专项服务</el-breadcrumb-item>
                <el-breadcrumb-item>列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="">
                <!-- <button @click="openAdd()">添加一级</button> -->
                <VyAddBtn @click.native="openAdd()"></VyAddBtn>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" row-key="id" border @row-click="handleRowClick" ref="tab">
                <el-table-column prop="title" align="center" label="标题"></el-table-column>
                <el-table-column prop="url" align="center" label="链接"></el-table-column>
                <el-table-column prop="content" align="center" label="内容"></el-table-column>
                <el-table-column prop="image" align="center" label="图片">
                    <template slot-scope="{ row }">
                        <img :src="row.image" style="width: 100%" />
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="end_time" align="center" label="结束时间"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate" v-if="!row.pid">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_operate" v-if="row.id == 1">
                            <button @click="openSetChildren(row)">编辑子分类</button>
                        </div>
                        <div class="_delete" v-if="row.id != 1 && row.pid != 1">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加弹出框 -->
        <el-dialog
            title="添加弹出框"
            :visible.sync="addShow"
            width="60%"
            custom-class="edit"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form ref="form" :model="addForm" label-width="80px">
                <el-form-item label="标题">
                    <el-input v-model="addForm.title"></el-input>
                </el-form-item>
                <el-form-item label="内容">
                    <el-input v-model="addForm.content"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="addForm.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                    >
                    </el-switch>
                </el-form-item>
                <el-form-item label="图片">
                    <upImg :src="addForm.image" ref="add"></upImg>
                </el-form-item>
                <el-form-item label="链接">
                    <el-input v-model="addForm.url"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addShow = false">取消</el-button>
                <el-button type="primary" @click="yes_add">提交</el-button>
            </span>
        </el-dialog>
        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" :visible.sync="setShow" width="60%" custom-class="edit" :show-close="false" :before-close="bfc">
            <el-form ref="form" :model="setForm" label-width="80px">
                <el-form-item label="标题" v-if="setForm.id != 1">
                    <el-input v-model="setForm.title"></el-input>
                </el-form-item>
                <el-form-item label="内容" v-if="setForm.id != 1">
                    <el-input v-model="setForm.content"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="setForm.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                    >
                    </el-switch>
                </el-form-item>
                <el-form-item label="图片">
                    <upImg :src="setForm.image" @removeSrc="setForm.image = ''" ref="set"></upImg>
                </el-form-item>
                <el-form-item label="链接" v-if="setForm.id != 1">
                    <el-input v-model="setForm.url"></el-input>
                </el-form-item>
                <el-form-item label="结束时间" v-if="setForm.id == 1">
                    <el-date-picker v-model="setForm.end_time" type="date" placeholder="选择结束日期"> </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setShow = false">取消</el-button>
                <el-button type="primary" @click="yes_set">提交</el-button>
            </span>
        </el-dialog>
        <!-- 编辑子分类弹出框 -->
        <el-dialog
            title="编辑子分类"
            :visible.sync="setChildrenShow"
            width="60%"
            custom-class="edit"
            :show-close="false"
            :before-close="bfc"
        >
            <children v-if="setChildrenShow" :list="list" ref="chi"></children>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setChildrenShow = false">取消</el-button>
                <el-button type="primary" @click="yes_setChildren">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import qs from "qs";
import axios from "axios";
import upImg from "../../customs/uploadImg.vue";
import children from "./children.vue";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";

export default {
    components: { upImg, children, VyAddBtn },
    data() {
        return {
            tableData: [],
            list: [],
            addForm: {
                title: "",
                content: "",
                status: true,
                image: "",
            },
            addShow: false,
            setForm: {},
            setShow: false,
            setChildrenShow: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },
    methods: {
        // 获取列表数据
        async getData() {
            let { data } = await axios.get("/api/special/show");
            return data;
        },
        // 打开编辑框
        openSet(row) {
            this.setForm = {
                ...row,
                status: Boolean(row.status),
            };
            this.setShow = true;
        },
        // 确认编辑
        async yes_set() {
            let { setForm } = this;
            if (setForm.id == 1) {
                var { data } = await axios.put(
                    "/api/special/actedit",
                    qs.stringify({
                        status: Number(this.setForm.status),
                        end_time: this.timeSet(this.setForm.end_time),
                        image: this.$refs.set.url,
                    })
                );
            } else {
                var { data } = await axios.put(
                    "/api/special/update",
                    qs.stringify({
                        ...setForm,
                        status: Number(this.setForm.status),
                        image: this.$refs.set.url,
                    })
                );
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
            this.setShow = false;
        },
        // 打开编辑子分类框
        openSetChildren(row) {
            // children
            this.list = row.children;
            this.setChildrenShow = true;
        },
        // 确认编辑子分类
        async yes_setChildren() {
            let { data } = await axios.put(
                "/api/special/actupdate",
                qs.stringify({
                    children: this.$refs.chi.getData(),
                })
            );
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.setChildrenShow = false;
            this.tableData = await this.getData();
        },
        // 打开添加框
        openAdd() {
            this.addShow = true;
        },
        // 确认添加
        async yes_add() {
            this.addForm.image = this.$refs.add.url;
            this.addForm.status = Number(this.addForm.status);
            let { data } = await axios.post("/api/special/add", qs.stringify(this.addForm));
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.addForm = {
                title: "",
                content: "",
                status: true,
                image: "",
            };
            this.tableData = await this.getData();
            this.addShow = false;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/special/del", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
                this.tableData = await this.getData();
            }
        },
        timeSet(dateObj, num) {
            if (typeof dateObj === "string") return dateObj;
            if (num === 24) {
                let year = dateObj.getFullYear();
                let month = dateObj.getMonth() + 1;
                let day = dateObj.getDate();
                let hours = dateObj.getHours();
                let minutes = dateObj.getMinutes();
                let seconds = dateObj.getSeconds();
                month = month < 10 ? "0" + month : month;
                day = day < 10 ? "0" + day : day;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
            }
            return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
        },
        // 点击行
        handleRowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_headerSecondary {
        display: flex;
        align-items: center;
        margin-top: 20px;
        text-align: right;
        & > div {
            flex: 1;
        }
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
